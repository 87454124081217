import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import parse from 'html-react-parser';
import Section from '../../components/containers/section';
import SectionContent from '../../components/containers/section-content';
import Copy from '../../components/content/copy';
import Heading from '../../components/content/heading';
import { Columns, Content, Rows } from '../../components/grid';
import Main from '../../components/layout/main';
import CtaGroup from '../../components/containers/cta-group';
import Listing from '../../components/blog/listing';
import AdListing from '../../components/blog/ad-listing';
import TryUsBar from '../../components/sections/try-us-bar';
import Pagination from '../../components/interactive/pagination';
import Seo from '../../components/seo/seo';
import Link from '../../components/content/link';

function AuthorPage({ pageContext, data }) {
  const { cta } = data.wpPage.blogPage;
  const {
    firstName, lastName, authorDetails, seo,
  } = data.wpUser;
  const authorImage = getImage(authorDetails.coreDetails.profileImage.localFile);

  const seoData = {
    title: authorDetails.pageDetails.seo.pageTitle,
    description: authorDetails.pageDetails.seo.metaDescription,
    url:
      pageContext.currentPage > 1
        ? `/${pageContext.slug}/${pageContext.currentPage}`
        : `/${pageContext.slug}/`,
  };

  const socialMediaIcons = [];
  const {
    instagram, linkedIn, facebook, twitter, youTube,
  } = seo.social;

  if (facebook) {
    socialMediaIcons.push(
      <Link className="h4" to={facebook} blank nofollow key="facebook">
        <FontAwesomeIcon icon={faFacebookSquare} />
      </Link>,
    );
  }

  if (instagram) {
    socialMediaIcons.push(
      <Link className="h4" to={instagram} blank nofollow key="instagram">
        <FontAwesomeIcon icon={faInstagram} />
      </Link>,
    );
  }

  if (youTube) {
    socialMediaIcons.push(
      <Link className="h4" to={youTube} blank nofollow key="youTube">
        <FontAwesomeIcon icon={faYoutube} />
      </Link>,
    );
  }

  if (linkedIn) {
    socialMediaIcons.push(
      <Link className="h4" to={linkedIn} blank nofollow key="linkedin">
        <FontAwesomeIcon icon={faLinkedinIn} />
      </Link>,
    );
  }

  if (twitter) {
    socialMediaIcons.push(
      <Link
        className="h4"
        to={`https://twitter.com/${twitter}`}
        blank
        nofollow
        key="twitter"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </Link>,
    );
  }

  if (socialMediaIcons.length) {
    socialMediaIcons.unshift(
      <p key="seperator" className="h4">
        |
      </p>,
    );
  }

  return (
    <Main>
      <Seo customData={seoData} />
      <header>
        <Section className="blog__header" wave="boat" theme="dark">
          <SectionContent hero>
            <Rows gap="sm" className="mb-md">
              <Columns rowgap count="1">
                <div className="flex flex--align-center mt-xl">
                  <Content>
                    <GatsbyImage
                      className="blog__featured-post--author__image"
                      image={authorImage}
                      alt={authorDetails.coreDetails.profileImage.altText}
                    />
                  </Content>
                  <Content paddingLeft="none" gap="xs">
                    <Heading level={2}>
                      {firstName}
                      {' '}
                      {lastName}
                    </Heading>
                    <CtaGroup>
                      <p className="h4">
                        {authorDetails.coreDetails.content.jobTitle}
                      </p>
                      {socialMediaIcons.length && socialMediaIcons}
                    </CtaGroup>
                  </Content>
                </div>
                <Content>
                  <Copy>
                    {parse(authorDetails.coreDetails.content.biography)}
                  </Copy>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="sm">
          <Rows gap="xl">
            <div className="blog__articles">
              {data.allWpPost.nodes.map((item, index) => (
                <Listing key={index} item={item} />
              ))}
              <AdListing item={cta} />
            </div>
            <Pagination
              rootslug={pageContext.slug}
              numPages={pageContext.numPages}
              currentPage={pageContext.currentPage}
            />
          </Rows>
        </SectionContent>
      </Section>
      <TryUsBar />
    </Main>
  );
}

export const query = graphql`
  query AuthorPageQuery($authorId: String!, $skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: { author: { node: { id: { eq: $authorId } } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        date(formatString: "MMMM Do, YYYY")
        author {
          node {
            firstName
            lastName
          }
        }
        realSlug
        categories {
          nodes {
            name
            slug
            data {
              color
            }
          }
        }
        excerpt
        title
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 915, quality: 90, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "blog" }) {
      blogPage {
        cta {
          link
          title
          content
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    wpUser(id: { eq: $authorId }) {
      firstName
      lastName
      seo {
        social {
          instagram
          linkedIn
          facebook
          twitter
          youTube
        }
      }
      authorDetails {
        coreDetails {
          profileImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 112, quality: 90, layout: CONSTRAINED)
              }
            }
          }
          content {
            biography
            jobTitle
          }
        }
        pageDetails {
          seo {
            metaDescription
            pageTitle
            schema
          }
        }
      }
    }
  }
`;

export default AuthorPage;
